import { cn } from "@/utils/cn";
import { Paragraph } from "@umahealth/occipital";
import { Icon, IIcon } from "@umahealth/occipital/client";
import { MouseEventHandler, ReactNode } from "react";

export function CardIcon({
	name,
	color = "text-grey-500",
	text,
}: {
	name: IIcon["name"];
	color?: IIcon["color"];
	text?: string;
}) {
	return (
		<div className="flex h-full self-start align-middle items-center">
			<Icon className="mr-4" color={color} name={name} size="size-6" />
			{text && (
				<Paragraph className="italic" color={color} size="text-s">
					{text}
				</Paragraph>
			)}
		</div>
	);
}

export function CardCTA({
	name,
	onClick,
	"aria-label": ariaLabel,
}: {
	name: IIcon["name"];
	onClick?: MouseEventHandler<HTMLButtonElement>;
	"aria-label": string;
}) {
	return (
		<button
			aria-label={ariaLabel}
			className="h-full ml-auto"
			onClick={onClick}
			type="button"
		>
			<Icon aria-hidden size="size-5" color="text-grey-500" name={name} />
		</button>
	);
}

export function CardContent({ children }: { children: ReactNode }) {
	return <div className="pr-4">{children}</div>;
}

export function CardContainer({
	children,
	className,
}: {
	children: ReactNode;
	className?: string;
}) {
	return (
		<div
			className={cn(
				" flex items-center align-middle w-full border-solid border border-grey-400 bg-background-light rounded-lg p-4 mb-6",
				className
			)}
		>
			{children}
		</div>
	);
}
