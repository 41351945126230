import(/* webpackMode: "eager" */ "/app_next/node_modules/@radix-ui/react-toast/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app_next/node_modules/@umahealth/occipital/dist/client.js");
;
import(/* webpackMode: "eager" */ "/app_next/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app_next/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app_next/node_modules/react-loading-skeleton/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app_next/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager" */ "/app_next/public/assets/uma.png");
;
import(/* webpackMode: "eager", webpackExports: ["TrenesOperationView"] */ "/app_next/src/modules/operations/(trenes)/app/TrenesOperationView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app_next/src/modules/operations/app/views/OperationsView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollArea"] */ "/app_next/src/storybook/components/Atoms/Scrollarea/Scrollarea.tsx");
