import { Unsubscribe as AuthUnsubscribe } from "firebase/auth";
import { Unsubscribe as FirestoreUnsubscribe } from "firebase/firestore";
import { Unsubscribe as DatabaseUnsubscribe } from "firebase/database";
import { useEffect } from "react";
import {
	useQuery,
	useQueryClient,
	QueryKey,
	QueryFunction,
	UseQueryOptions,
	UseQueryResult,
} from "@tanstack/react-query";

type Unsubscribe = AuthUnsubscribe | FirestoreUnsubscribe | DatabaseUnsubscribe;

const unsubscribes: Record<string, Unsubscribe | null> = {};
const observerCount: Record<string, number> = {};
const eventCount: Record<string, number> = {};

type UseSubscriptionOptions<TData extends unknown | null, TError, R> = UseQueryOptions<
	TData,
	TError,
	R
> & {
	onlyOnce?: boolean;
	fetchFn?: () => Promise<TData>;
};
export function useSubscription<TData extends unknown | null, TError, R = TData>(
	queryKey: QueryKey,
	subscriptionKey: QueryKey,
	subscribeFn: (
		callback: (data: TData | null) => Promise<void>
	) => Unsubscribe,
	options?: UseSubscriptionOptions<TData, TError, R>
): UseQueryResult<R, TError> {
	const queryClient = useQueryClient();
	const subscriptionHash = JSON.stringify(subscriptionKey);

	// Función para limpiar suscripciones cuando ya no hay observadores
	const cleanupSubscription = (subscriptionHash: string) => {
		if (observerCount[subscriptionHash] === 0) {
			unsubscribes[subscriptionHash]?.();
			delete unsubscribes[subscriptionHash];
			delete eventCount[subscriptionHash];
		}
	};

	useEffect(() => {
		observerCount[subscriptionHash] =
			(observerCount[subscriptionHash] || 0) + 1;

		return () => {
			observerCount[subscriptionHash] -= 1;
			cleanupSubscription(subscriptionHash);
		};
	}, [subscriptionHash]);

	const result: Promise<TData | null> = new Promise((resolve, reject) => {
		if (!options?.onlyOnce) {
			if (unsubscribes[subscriptionHash]) {
				const previousData = queryClient.getQueryData<TData | null>(
					queryKey
				);
				resolve(previousData ?? null);
			} else {
				const unsubscribe = subscribeFn( async(data: TData | null) => {
					eventCount[subscriptionHash] =
						(eventCount[subscriptionHash] || 0) + 1;
					if (eventCount[subscriptionHash] === 1) {
						resolve(data); // Resolver en el primer evento
					} else {
						queryClient.setQueryData(queryKey, data);
					}
				});
				unsubscribes[subscriptionHash] = unsubscribe;
			}
		} else {
			if (options.fetchFn) {
				options.fetchFn().then(resolve).catch(reject);
			} else {
				reject(new Error("fetchFn es obligatorio en modo onlyOnce."));
			}
		}
	});

	result.catch(() => {
		queryClient.invalidateQueries({ queryKey });
	});

	const queryFn: QueryFunction<TData, QueryKey> = async () => {
		return result as Promise<TData>;
	};

	return useQuery<TData, TError, R>({
		queryKey,
		queryFn,
		...options,
		staleTime: Infinity,
		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
		refetchInterval: false,
		retry: false,
	});
}
