import {
	UseQueryOptions,
	UseQueryResult,
} from "@tanstack/react-query";
import { AuthError, IdTokenResult } from "firebase/auth";
import { useSubscription } from "./useSubscription";
import { auth } from "@/config/firebaseApp";

export function useAuthIdToken<R = { token: IdTokenResult }>(
	options?: Omit<
		UseQueryOptions<{ token: IdTokenResult }, AuthError, R>,
		"queryFn"
	>
): UseQueryResult<R, AuthError> {
	const subscribeFn = (
		callback: (data: { token: IdTokenResult } | null) => Promise<void>
	) =>
		auth.onIdTokenChanged(async (data) => {
			const token = await data?.getIdTokenResult();

			return callback(token ? { token } : null);
		});

	return useSubscription<{ token: IdTokenResult }, AuthError, R>(
		["authIdToken"],
		["useAuthIdToken"],
		subscribeFn,
		{ ...(options ?? {}), queryKey: ["authIdToken"] } // Se asegura de que queryKey esté en las opciones
	);
}
