'use client'
import { base_list_url, role_list_url, user_url } from '@/config/endpoints'
import { errorHandler } from '@/config/errorReporting'
import { objectToQueryParams, removeEmptyValuesFromObject } from '@/utils/functions'
import { IUserBase, IUserRole } from '../domain/IUser'
import { IUserFilters } from '../domain/UsersTable'
import { User } from 'firebase/auth'
import { getFirebaseTokenFromCookies } from "@/modules/users/service/cookies";

export async function fetchUsers({
	filters,
	page,
	currentUser,
}: {
	filters: IUserFilters;
	page: number;
	currentUser: User}) {
	// CSR
	const firebaseToken = await currentUser.getIdToken();
	
	try {
		const headers = {
			Authorization: `Bearer ${firebaseToken}`,
		};
		const queryParams = objectToQueryParams({
			...removeEmptyValuesFromObject({ ...filters, page }),
		});

		const users = await fetch(`${user_url}/usersList?${queryParams}`, {
			cache: "no-store",
			headers,
		});
		if (!users.ok) throw new Error(users.statusText);

		return await users.json();
	} catch (err) {
		errorHandler?.report(`Error en request de obtener usuarios: ${err}`);
		throw err;
	}
}

export async function getRoleList(): Promise<IUserRole[]> {
  // CSR
  try{
    const firebaseToken = await getFirebaseTokenFromCookies()
    const headers = {
      'Authorization': `Bearer ${firebaseToken}`
    }
    const roles = await fetch(role_list_url, { cache: 'no-store', headers })
    if(!roles.ok) throw new Error(roles.statusText)

    return await roles.json()
  } catch (err) {
    errorHandler?.report(`Error en request de obtener lista de roles: ${err}`);
    throw err;
  }
}

export async function getBaseList({
	currentUser,
}: {
	currentUser: User;
}): Promise<IUserBase[]> {
	// CSR
	try {
		const firebaseToken = await currentUser?.getIdToken();
		const headers = {
			Authorization: `Bearer ${firebaseToken}`,
		};
		const bases = await fetch(base_list_url, {
			cache: "no-store",
			headers,
		});
		if (!bases.ok) throw new Error(bases.statusText);

		return await bases.json();
	} catch (err) {
		errorHandler?.report(
			`Error en request de obtener lista de bases: ${err}`
		);
		throw err;
	}
}

