import React, { Dispatch, ReactNode, SetStateAction } from 'react'
import { IconX } from '@tabler/icons-react'

interface ModalProps {
    title: string
    children: ReactNode
    status: boolean
    setStatus: Dispatch<SetStateAction<boolean>>
    className?: string
}

const Modal = ({ title, children, status, setStatus, className }: ModalProps) => {
    return (
        <>
            {status && (
                <div className="fixed top-0 left-0 z-[1000] w-screen h-screen bg-black/50 p-10 flex justify-center items-center">
                    <div className={`relative min-h-[100px] max-h-screen bg-white rounded-lg p-5 m-4 shadow-lg ${className ? className : ''}`}>
                        <header className="flex justify-center mb-4">
                            <h3 className="text-lg font-bold text-black">{title}</h3>
                        </header>
                        <div className="absolute top-5 right-4 w-8 h-8 border-none cursor-pointer" onClick={() => setStatus(false)}>
							<IconX size={28} stroke={1} />
                        </div>
                        <div className='w-full h-full py-6 overflow-y-auto'>
							{children}
						</div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Modal
