import React, { useEffect, useState } from 'react'
import { IOperation, IOperationResponse, ITest, IUserBase } from '@umahealth/entities';
import { fetchOperations } from '../../infraestructure/operationsRequets';
import Alert from './Alert';

const AlertChecker = ({baseId} : { baseId : number}) => {
    const operationsInitialState: IOperationResponse = { total: 0, operations: [] };
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [operations, setOperations] = useState<IOperationResponse>(operationsInitialState);
    const [operationsWithAlert, setOperationsWithAlert] = useState<IOperation[]>([]);
    const base_id = baseId ?? 0;

    function orderByDtCreateDesc(arr: ITest[]) {
        const newArr = [...arr];
        newArr.sort((a, b) => {
            const dateA = new Date(a.dt_create);
            const dateB = new Date(b.dt_create);
            return dateB.getTime() - dateA.getTime();
        });
        return newArr;
      }

    useEffect(() => {
        const getInProgresOperations = async () => {
            const operationsRequest: IOperationResponse = await fetchOperations(
				{
					filters: {
						type: "all",
						driver: null,
						from: null,
						to: null,
						base: base_id,
					},
					page: null,
					exportCSV: true,
				}
			);
            setIsLoading(false);
            setOperations(operationsRequest);
        }

        getInProgresOperations();

        const intervalMinutes = 10;
        const checkerInterval = setInterval(() => {
            getInProgresOperations();
        }, (1000 * 60) * intervalMinutes);

        return () => clearInterval(checkerInterval)
    }, [base_id]);
    
    useEffect(() => { // When operations state changes verify
        if(operations?.total === 0) {
            setOperationsWithAlert([]);
            return;
        }

        const checkOperations = (operations: IOperation[]) => {
            const currentTime = new Date();
            let alertsReaded: number[];

            try {
                alertsReaded = JSON.parse(
                  localStorage.getItem("alertsReaded") || [].toString()
                );
              } catch (error) {
                alertsReaded = [];
              }

             return operations.filter((operation) => {
               if (operation.tests && operation.tests.length > 0) {
                 const testOrdered = orderByDtCreateDesc(operation.tests);
                
                 // if test id is in alertsReaded array ignore it
                 if(alertsReaded.includes(testOrdered[0].id)) return false;

                 const dtCreateTest = new Date(testOrdered[0].dt_create);
                 const timeDifferenceHours = (currentTime.getTime() - dtCreateTest.getTime()) / (1000 * 60 * 60);
     
                 return timeDifferenceHours > 4;
               }
               return false;
             });
           }

        const operationsChecked = checkOperations(operations.operations);
        setOperationsWithAlert(operationsChecked)
    }, [operations])


    

    if(!operationsWithAlert || operationsWithAlert.length === 0 || isLoading) return <></>;

  return (
    <Alert data={operationsWithAlert} setOperations={setOperations} />
  )
}

export default AlertChecker