import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { IBaseCombobox } from "../../../Inputs/Combobox/BaseCombobox/BaseCombobox";
import { ISearchDashboard } from "../../../Inputs/Combobox/SearchDashboard/SearchDashboard";
import { FilterByRange, IFilterObject } from "./FilterDashboard";
import {
	ColumnFiltersState,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	OnChangeFn,
	SortingState,
	Table,
	useReactTable,
} from "@tanstack/react-table";
import { Icon, Input } from "@umahealth/occipital/client";
import { FilterRangeTime } from "../../../Inputs/RangeDayPicker/RangeDayPicker";
import * as XLSX from "xlsx";
import { IDashboard } from "../AlertAI/Dashboard.stories";
import { format } from "date-fns";
import { Button } from "@umahealth/occipital";
import {
	operationColumns,
	DashboardColumnsIds,
} from "./TrenesOperationsColumns";
import {
	DataTableProps,
	TrenesDashboardTable,
} from "./TrenesDashboardTable";
import { IGetOperation } from "@/modules/operations/(trenes)/infraestructure/useGetOperations";
import { IOperation } from "@umahealth/entities";
import * as Collapsible from "@radix-ui/react-collapsible";
import { cn } from "@/utils/cn";

interface ExportToExcelProps {
	table: Table<IDashboard>;
}


export type IDashboardElement = Omit<
	DataTableProps &
		ISearchDashboard & {
			bases: IBaseCombobox["bases"];
			fetchMoreOnBottomReached: (event: HTMLDivElement) => void;
		},
	"inputLabel" | "table"
> & {
	filters: IGetOperation["filters"];
	setFilters: Dispatch<SetStateAction<IGetOperation["filters"]>>;
};

const exportToExcel = ({ table }: ExportToExcelProps) => {
	// Obtener los datos filtrados y visibles
	const visibleRows = table
		.getFilteredRowModel()
		.rows.map((row) => row.original);

	// Definir las columnas a exportar
	const headers = table.getAllColumns().map((column) => {
		return column.columnDef.id;
	});

	// Crear una hoja de trabajo (worksheet)
	const worksheetData = [
		headers,
		...visibleRows.map((row) =>
			table
				.getAllColumns()
				.map((column) => row[column.id as keyof IDashboard])
		),
	];
	const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

	// Crear un libro de trabajo (workbook) y añadir la hoja de trabajo
	const workbook = XLSX.utils.book_new();
	XLSX.utils.book_append_sheet(workbook, worksheet, "Datos");

	// Obtener la fecha y hora actual formateada
	const now = new Date();
	const formattedDate = format(now, "HH_mm_dd_MM_yyyy");

	// Generar el nombre del archivo con la fecha y hora
	const fileName = `table_${formattedDate}.xlsx`;

	// Generar el archivo Excel y descargarlo
	XLSX.writeFile(workbook, fileName);
};

const operationType = [
	{
		id: "in-progress",
		label: "En proceso",
	},
	{
		id: "finished",
		label: "Finalizada",
	},
	{
		id: "all",
		label: "Todas",
	},
	{
		id: "in-review",
		label: "En curso",
	},
	{
		id: "to-apt-review",
		label: "esperando revisión del doctor",
	},
	{
		id: "to-apt-review-call-requested",
		label: "esperando videollamada del doctor",
	},
] as const satisfies IFilterObject[] satisfies {
	id: IOperation["status"] | "all";
	label: string;
}[];

const OperationsDashboard = ({ setFilters, filters, ...props } : IDashboardElement) => {
		const [sorting, setSorting] = useState<SortingState>([]);
		const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
			[]
		);
		const [searchValue, setSearchValue] = useState<string | null>(null);
		const [open, setOpen] = useState(false);
		const tableContainerRef = useRef<HTMLDivElement>(null);

		//scroll to top of table when sorting changes
		const handleSortingChange: OnChangeFn<SortingState> = (updater) => {
			setSorting(updater);
			if (
				!!table.getRowModel().rows.length &&
				tableContainerRef &&
				"current" in tableContainerRef
			) {
				tableContainerRef.current?.scrollTo({
					top: 0,
					behavior: "smooth", // Para un desplazamiento suave
				});
			}
		};

		//a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
		useEffect(() => {
			if (tableContainerRef.current) {
				props.fetchMoreOnBottomReached(tableContainerRef.current);
			}
		}, [props]);

		const table = useReactTable({
			data: props.data ?? [],
			columns: operationColumns,
			getCoreRowModel: getCoreRowModel(),
			onSortingChange: handleSortingChange,
			getSortedRowModel: getSortedRowModel(),
			onColumnFiltersChange: setColumnFilters,
			getFilteredRowModel: getFilteredRowModel(),
			state: {
				sorting,
				columnFilters,
				/* Mostramos todas las columnas, la idea es después dependiendo del rol decidir qué mostrar. */
				columnVisibility: {
					actions: true,
					base: true,
					date: true,
					doctor: true,
					enfermero: true,
					fullname: true,
					id: true,
					status: true,
				} satisfies Record<DashboardColumnsIds, boolean>,
			},
			/** esto es para que cada row tenga un id único, correspondiente al id que viene de la DB */
			getRowId: (originalRow) =>
				typeof originalRow.id === "number"
					? originalRow.id.toString()
					: originalRow.id,
		});

		return (
			<div className="p-8 pt-0 rounded-xl bg-[#FCFCFC] space-y-8 overflow-hidden ">
				<div className="flex flex-col align-bottom">
					<Collapsible.Root
						className="w-full flex-col"
						open={open}
						onOpenChange={setOpen}
					>
						<div className="flex items-center w-full mt-6">
							<Input
								classNameContainer="w-full mt-0  mr-2 max-w-600 w-full"
								label="Busca por nombre del Operador o número de legajo "
								onChange={(target) => {
									table
										.getColumn("fullname")
										?.setFilterValue(target.target.value);
									setSearchValue(target.target.value);
								}}
								value={searchValue ?? undefined}
							/>
							<Collapsible.Trigger asChild>
								<button
									className={cn(
										"flex text-grey-500 space-x-2 p-3 rounded-md ",
										open
											? " text-grey-800 border border-solid border-grey-100 shadow-md"
											: undefined
									)}
								>
									<Icon name="file" size="size-6" />
									<span>Filtrar</span>
									<Icon
										name="chevronDown"
										className={open ? "rotate-180" : ""}
										size="size-6"
									/>
								</button>
							</Collapsible.Trigger>
						</div>

						<div className="flex mt-2 w-full justify-between items-center">
							<Collapsible.Content>
								<div className="flex">
									<FilterByRange
										allElements={operationType}
										elementsToSee={
											filters?.type === "all" ||
											filters?.type === undefined
												? [
														{
															id: "all",
															label: "Todas",
														},
													]
												: operationType.filter(
														(operation) => {
															if (
																filters?.type &&
																filters?.type !==
																	"all"
															) {
																filters?.type.some(
																	(
																		filterType
																	) =>
																		filterType ===
																		operation.id
																);
															}
														}
													)
										}
										columnToFilter={table.getColumn(
											"status"
										)}
										setFilters={setFilters}
										filters={filters}
										title={"Estado"}
									/>
									<FilterRangeTime
										selectedRange={{
											from: filters?.from ?? undefined,
											to: filters?.to ?? undefined,
										}}
										setSelectedRange={(dates) => {
											setFilters({
												...(filters || {}),
												from: dates.from,
												to: dates.to,
											});
										}}
									/>
								</div>
							</Collapsible.Content>
							<div />

							<Button
								className="mt-2"
								size="extended"
								type="button"
								onClick={() => {
									exportToExcel({ table });
								}}
							>
								Descargar datos
							</Button>
						</div>
					</Collapsible.Root>
				</div>
				<TrenesDashboardTable
					ref={tableContainerRef}
					columns={props.columns}
					data={props.data}
					fetchMoreOnBottomReached={props.fetchMoreOnBottomReached}
					table={table}
				/>
			</div>
		);
	}

OperationsDashboard.displayName = "Dashboard";

export default OperationsDashboard;
