import React from 'react'
import Link from 'next/link'
import swal from 'sweetalert'
import { errorHandler } from '@/config/errorReporting'
import { IconFile, IconFlagCheck, IconId, IconReportMedical } from '@tabler/icons-react'
import { closeOperation } from '../../infraestructure/operationsRequets'

export function ViewDriverDetails({ uid }: { uid: string }) {
    return (
        <Link href={`/operador/${uid}`} className="flex items-center" title="Ver operador">
            <IconId size={30} stroke={1} />
        </Link>
    )
}

export function ViewOperationDetails({ operationId }: { operationId: number }) {
    return (
		<Link href={`/operaciones/${operationId}`} className="flex items-center" title="Ver operación">
			<IconFile size={28} stroke={1} />
		</Link>
    )
}

export function PhysicalExam({ operationId }: { operationId: number }) {
    return (
		<Link href={`/operador/examen-fisico/${operationId}`} className="flex items-center" title="Ver examen físico">
			<IconReportMedical size={28} stroke={1} />
		</Link>
    )
}

export function FinishOperation({ operationId }: { operationId: number }) {
    async function handleCloseOperation() {
        try {
            const closeConfirmation = await swal({
                title: 'Finalizar operación',
                text: '¿Deseas finalizar esta operación?',
                icon: 'info',
                buttons: ['No', 'Si'],
            })
            if (!closeConfirmation) return false
            await closeOperation(operationId)
            await swal('Exito', 'Operación finalizada exitosamente', 'success')
            window.location.reload()
        } catch (err) {
            swal('Ocurrió un problema', 'No se pudo finalizar la operación, vuelva a intentarlo', 'warning')
            errorHandler?.report(`Error finalizando la operación ID ${operationId}: ${err}`)
        }
    }
    return (
        <button onClick={handleCloseOperation} title="Finalizar operación" className='w-fit mx-auto'>
            <IconFlagCheck size={28} stroke={1} />
        </button>
    )
}