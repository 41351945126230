import { differenceInMinutes, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { Company, TAppModuleId } from "@/modules/company/domain/ICompany";
import {
	AlarmContent,
	CVAnomalyDetection,
	CVFatigueDetector,
	NLPFatiguePrediction,
} from "@umahealth/entities/src/entities/alertai/interfaces/Alarm";
import { IOperation } from "@umahealth/entities";
import { ITestResult } from "src/storybook/pages/operación/VIewOperation/ViewOperation";

export function generateRandomString() {
	return Math.floor(Math.random() * Date.now()).toString(36);
}

function formatDuration(start: Date, end: Date) {
	const totalMinutes = differenceInMinutes(end, start);
	const hours = Math.floor(totalMinutes / 60);
	const minutes = totalMinutes % 60;

	const hoursText = hours === 1 ? "1 hora" : `${hours} horas`;
	const minutesText = minutes === 1 ? "1 minuto" : `${minutes} minutos`;
	const duration = `${hours > 0 ? hoursText : ""} ${minutes > 0 ? minutesText : ""}`;

	return duration;
}

export function checkNLPFatigueAlarm(
	model: NLPFatiguePrediction | null
): boolean {
	const isAlarm =
		model !== null &&
		model.report === true &&
		model.fatigue === true &&
		model.human_tag === null;

	return isAlarm;
}

export function checkAnomalyDetectionAlarm(
	model: CVAnomalyDetection | null
): boolean {
	const isAlarm =
		model !== null &&
		model.anomaly_detected === true &&
		model.human_tag === null;

	return isAlarm;
}

export function checkCVFatigueDetectorAlarm(
	model: CVFatigueDetector | null
): boolean {
	const isAlarm =
		model !== null &&
		model.fatigue_detected === true &&
		model.human_tag === null;

	return isAlarm;
}

export function checkOperationsHasAlarm(tests: IOperation["tests"]) {

	let hasAlarm : boolean = false;

	tests.forEach(test => {

		if (typeof test.fatigue_catboost_result != 'boolean'){
			console.log('revisar operación:', test.operationId, test.id)
		}
			if (test.fatigue_catboost_result) {
				hasAlarm = true;
			}
	})

	return hasAlarm
}


export function getAlarmFromTests(
	tests: IOperation["tests"]
): AlarmContent | null {
	if (!tests) {
		return null;
	}

	let sampleNumber = 1;
	let reactionTimeAverage = 0;
	const test = tests.find((test, index) => {
		if (!test.video) return;
		sampleNumber = index + 1;
		reactionTimeAverage = Math.round(
			test?.reaction.reduce((acc, el) => el.reaction_time + acc, 0) /
				test.reaction?.length
		);

		return (
			checkCVFatigueDetectorAlarm(test.video.CVFatigueDetector || null)
		);
	});

	if (!test?.video) return null;

	return {
		sampleNumber,
		videoUrl: test?.video.video,
		videoRecordedAt: new Date(test?.dt_create),
		reactionTimeAverage: reactionTimeAverage,
		NLPFatiguePrediction: test?.video?.NLPFatiguePrediction || null,
		CVAnomalyDetection: test?.video?.CVAnomalyDetection || null,
		CVFatigueDetector: test?.video?.CVFatigueDetector || null,
		journeyDuration: "",
	};
}

export function getAlarmsFromOperations(operations: IOperation[]) {
	return operations.flatMap((operation) => {
		const alarmsIds: string[] = [];
		const NLPFatigueAlarms = operation.tests
			?.filter(
				(test) =>
					test.video &&
					checkNLPFatigueAlarm(
						test.video.NLPFatiguePrediction || null
					)
			)
			.map((test) =>
				alarmsIds.push(
					`NLPFatigue-${test.video?.NLPFatiguePrediction?.id}`
				)
			);

		const AnomalyDetectionAlarms = operation.tests
			?.filter(
				(test) =>
					test.video &&
					checkAnomalyDetectionAlarm(
						test.video.CVAnomalyDetection || null
					)
			)
			.map((test) =>
				alarmsIds.push(
					`AnomalyDetection-${test.video?.CVAnomalyDetection?.id}`
				)
			);

		const CVFatigueDetectorAlarms = operation.tests
			?.filter(
				(test) =>
					test.video &&
					checkCVFatigueDetectorAlarm(
						test.video.CVFatigueDetector || null
					)
			)
			.map((test) =>
				alarmsIds.push(
					`CVFatigueDetector-${test.video?.CVFatigueDetector?.id}`
				)
			);

		return alarmsIds;
	});
}

export function compareAlarms(
	currentOperations: IOperation[],
	newOperations: IOperation[]
) {
	const currentAlarms = getAlarmsFromOperations(currentOperations);
	const newAlarms = getAlarmsFromOperations(newOperations);

	const areEquals =
		currentAlarms.length === newAlarms.length &&
		currentAlarms.every((num) => newAlarms.includes(num));

	return areEquals;
}

export function getReactionTimeColor(ms: number) {
	if (ms < 600) {
		return "#259B38";
	} else if (ms < 750) {
		return "#C4942B";
	} else if (ms >= 750) {
		return "#FF0000";
	} else {
		return "#000000";
	}
}

export function isModuleActive(company: Company, appModuleId: TAppModuleId) {
	return Boolean(
		company?.modules.find(
			(module) => module.active && module.app_module.id === appModuleId
		)
	);
}
