import { Paragraph, Title } from "@umahealth/occipital";
import { Icon } from "@umahealth/occipital/client";
import Link from "next/link";
import { ReactNode } from "react";
import { cn } from "src/shadcn/lib/utils";
import { ScrollArea } from "../../Atoms/Scrollarea/Scrollarea";

export function OperationCardScrollArea({
	children,
}: {
	children: ReactNode;
}) {
	return (
		<ScrollArea className="w-full h-full p-16 pt-0 pb-0" type="auto">
			<div className="p-4 overflow-visible">
				{children}
				</div>
		</ScrollArea>
	);
}

export function OperationCardStickyBottom({
	children,
}: {
	children: ReactNode;
}) {
	return (
		<div className="flex justify-between sticky top-auto bottom-0 bg-white pt-4 p-6 px-20 w-full">
			{children}
		</div>
	);
}

export function OperationCardStickyTop({
	children,
	backHref,
	ariaLabelBackButton,
	title,
	subtitle,
}: {
	/** Lugar donde debe retornar la flecha de retroceder, si no se especifica no habra flecha*/
	backHref?: string;
	title?: string;
	/** Texto auxiliar y opcional por debajo del título */
	subtitle?: string;
	children?: ReactNode;
	/** Texto descriptivo para el boton de retroceder */
	ariaLabelBackButton?: string;
}) {
	return (
		<div className="flex-col">
			<div className=" w-full flex pb-0 p-6 ">
				<div className="flex">
					{backHref && (
						<Link
							href={backHref}
							aria-label={ariaLabelBackButton ?? "retroceder"}
						>
							<Icon
								className="mt-[5px] mr-8"
								name="arrowBack"
								size="size-6"
								aria-hidden
							/>
						</Link>
					)}
					<div className="w-full h-full pt-0">
						<div className="w-full pt-0">
							<Title
								color="text-grey-900"
								className="text-lg"
								hierarchy="h2"
							>
								{title}
							</Title>
							{subtitle && (
								<Paragraph color="text-grey-400">
									{subtitle}
								</Paragraph>
							)}
						</div>
					</div>
				</div>
			</div>
			{children}
		</div>
	);
}

export function OperationCard({
	children,
	className,
}: {
	children?: ReactNode;
	className?: string}) {
	return (
		<section
			className={cn(
				"w-full h-full m-8 p-2 shadow-contenedor bg-white rounded-3xl max-w-[704px] flex ml-auto mr-auto overflow-hidden flex-col",
				className
			)}
		>
			{children}
		</section>
	);
}
