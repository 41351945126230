import StackdriverErrorReporter from "stackdriver-errors-js"

let stackDriver: null | StackdriverErrorReporter = null
// https://github.com/GoogleCloudPlatform/stackdriver-errors-js -> Docu
try {
	if(typeof window !== 'undefined'){
		stackDriver = new StackdriverErrorReporter()
		stackDriver.start({
			key: `${process.env.NEXT_PUBLIC_ERROR_REPORTING_API_KEY}`,
			projectId: `${process.env.NEXT_PUBLIC_PROJECT_ID}`,
			service: 'client-portal',
			version: "1",
		})
	}
} catch (err) {
	console.error(err)
}

export const errorHandler = process.env.NEXT_ISSERVER
	? {
			report: (message : string) => console.error(message),
		}
	: stackDriver;
