"use client"
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { Icon } from "@umahealth/occipital/client";
import { IOperationDashboard } from "src/storybook/pages/administracion/Operations/OperationsPage.stories";
import { getOperationById } from "@/modules/operations/(trenes)/infraestructure/operation Requests/server/getOperationById";
import Link from "next/link";
import { StatusText } from "src/storybook/components/StatusText/StatusText";
import { downloadOperationReport } from "@/modules/operations/(trenes)/app/components/DownloadOperationReport";

export const operationColumns = [
	{
		id: "date",
		accessorKey: "date",
		header: ({ column }) => {
			return (
				<div className="flex items-center">
					<div className="text-left font-normal mr-2">
						Fecha y hora
					</div>
					<button
						className="flex items-center"
						onClick={() =>
							column.toggleSorting(column.getIsSorted() === "asc")
						}
					>
						<Icon aria-hidden name={"arrowUpDown"} />
					</button>
				</div>
			);
		},
		sortingFn: "datetime",
		cell: ({ row }) => {
			const date = row.original.date;
			let formattedDate: string;
			let formattedTime: string;
			let accesibleDate: string;

			try {
				accesibleDate = date.toISOString();
			} catch {
				accesibleDate = "invalid";
			}

			try {
				formattedDate = format(date, "dd/MM/yyyy");
			} catch {
				formattedDate = "Fecha inválida";
			}
			try {
				formattedTime = format(date, "HH:mm");
			} catch {
				formattedTime = "hora inválida";
			}

			return (
				<div className="flex flex-col text-grey-400 ">
					<time
						dateTime={accesibleDate}
						aria-label={`Fecha ${formattedDate}`}
					>
						{formattedDate}
					</time>
					<time
						dateTime={accesibleDate}
						aria-label={`Hora ${formattedTime}`}
					>
						{formattedTime}
					</time>
				</div>
			);
		},
	},
	{
		id: "id",
		accessorKey: "id",
		header: () => {
			return <div className="font-normal">Id</div>;
		},
		cell: ({ row }) => {
			return (
				<div className="flex text-grey-400 flex-wrap">
					<p>{row.original.id}</p>
				</div>
			);
		},
	},
	{
		id: "fullname",
		accessorKey: "fullname",
		sortingFn: "text",
		header: ({ column }) => {
			return (
				<div className="flex items-center content-center">
					<div className="text-left font-normal mr-2">Operador</div>
					<button
						className="flex items-center"
						onClick={() =>
							column.toggleSorting(column.getIsSorted() === "asc")
						}
					>
						<Icon name="sortByAlpha" />
					</button>
				</div>
			);
		},

		cell: ({ row }) => {
			return (
				<div className="flex text-grey-400 flex-wrap">
					<p>{row.original.fullname}</p>
				</div>
			);
		},
	},
	{
		id: "enfermero",
		accessorKey: "id",
		header: ({ column }) => {
			return (
				<div className="flex items-center content-center">
					<div className="text-left font-normal mr-2">Enfermero</div>
					<button
						className="flex items-center"
						onClick={() =>
							column.toggleSorting(column.getIsSorted() === "asc")
						}
					>
						<Icon name="sortByAlpha" />
					</button>
				</div>
			);
		},
		cell: ({ row }) => {
			return (
				<div className="flex text-grey-400 flex-wrap">
					<p>{row.original.enfermero}</p>
				</div>
			);
		},
	},
	{
		id: "doctor",
		accessorKey: "id",
		header: ({ column }) => {
			return (
				<div className="flex items-center content-center">
					<div className="text-left font-normal mr-2">Doctor</div>
					<button
						className="flex items-center"
						onClick={() =>
							column.toggleSorting(column.getIsSorted() === "asc")
						}
					>
						<Icon name="sortByAlpha" />
					</button>
				</div>
			);
		},
		cell: ({ row }) => {
			return (
				<div className="flex text-grey-400 flex-wrap">
					<p>{row.original.doctor}</p>
				</div>
			);
		},
	},
	{
		id: "base",
		accessorKey: "base",
		header: () => <div className="text-left font-normal">Base</div>,
		cell: ({ row }) => {
			const base = row.original.base;
			return (
				<div className="flex text-grey-400 flex-wrap">
					<p>{base}</p>
				</div>
			);
		},
		filterFn: (row, id, filterValue: string[]) => {
			return filterValue.includes(row.getValue(id));
		},
	},
	{
		id: "status",
		accessorKey: "status",
		header: () => (
			<div className="text-left font-normal">Estado de la evaluación</div>
		),
		cell: ({ row }) => {
			const status = row.original.status;
			return <StatusText status={status} />;
		},
		filterFn: (row, id, filterValue: string[]) => {
			return filterValue.includes(row.getValue(id));
		},
	},
	{
		id: "actions",
		accessorKey: "actions",
		header: () => {
			return <div className="text-left font-normal">Acciones</div>;
		},
		cell: ({ row }) => {
			return (
				<div className="flex items-center">
					<button
						className="m-0 mr-4"
						onClick={() => {
							getOperationById(String(row.original.id))
								.then(downloadOperationReport)
								.catch((err) => {
									console.error(err);
									alert("Error al descargar");
								});
						}}
					>
						<Icon
							name="download"
							color="text-secondary-700"
							size="size-6"
						/>
					</button>
					<Link
						className="mr-0"
						aria-label={`Ir a la consulta ${row.original.id}`}
						href={`/operaciones/crear/${row.original.id}/observacion-general`}
					>
						<Icon
							aria-hidden
							name="chevronRight"
							color="text-grey-600"
							size="size-6"
						/>
					</Link>
				</div>
			);
		},
	},
] as const satisfies ColumnDef<IOperationDashboard>[]; 

export const columnsIds = operationColumns.map(column => column.id)

export type DashboardColumnsIds = (typeof operationColumns)[number]["id"];