"use client";
import { DateRange, DayPicker } from "react-day-picker";
import {
	Icon,
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@umahealth/occipital/client";
import { Button } from "@umahealth/occipital";
import { format } from "date-fns";
import { FilterRangeDates } from "../CalendarRange/FilterRangeDates";
import { SetStateAction } from "react";
import { cn } from "@/utils/cn";
export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function FilterRangeTimeContent({
	selectedRange,
}: {
	selectedRange: DateRange | undefined;
}) {
	const fromDate = selectedRange?.from
		? format(selectedRange?.from, "dd/MM/yy ")
		: undefined;
	const toDate = selectedRange?.to
		? format(selectedRange?.to, "dd/MM/yy ")
		: undefined;

	if (selectedRange === undefined) {
		return (
			<span className="mr-2 flex flex-nowrap overflow-ellipsis text-grey-500">
				Rango de fechas
			</span>
		);
	}

	if (selectedRange?.from && selectedRange?.to === undefined) {
		return (
			<span className="mr-2 flex flex-nowrap overflow-ellipsis text-primary-800">
				{fromDate}
			</span>
		);
	}

	return (
		<span className="mr-2 flex flex-nowrap overflow-ellipsis text-primary-800">
			{fromDate} - {toDate}
		</span>
	);
}

function FilterRangeTime({
	selectedRange,
	setSelectedRange,
}: {
	selectedRange: DateRange | undefined;
	setSelectedRange: (dates:DateRange) => void;
}) {
	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					className={cn(
						selectedRange
							? "border-primary-800 bg-primary-50"
							: "border-grey-500",
						"w-auto  text-grey-500 h-[50px] flex items-center justify-center mr-2 rounded-3xl  border border-solid"
					)}
					type="button"
					variant="outlined"
				>
					<div className="flex items-center justify-center">
						<FilterRangeTimeContent selectedRange={selectedRange} />
						<Icon
							name="chevronDown"
							size="size-6"
							color={
								selectedRange
									? "text-primary-800"
									: "text-grey-500"
							}
						/>
					</div>
				</Button>
			</PopoverTrigger>
			<PopoverContent className="flex flex-col w-min">
				<FilterRangeDates
					selectedRange={selectedRange}
					setSelectedRange={setSelectedRange}
				/>
			</PopoverContent>
		</Popover>
	);
}

export { FilterRangeTime };
