import { DateRange } from "react-day-picker"
import { CalendarRange, Icon } from "@umahealth/occipital/client";
import { format, getDate, getYear, setYear, startOfMonth } from "date-fns";
import { cn } from "@/utils/cn";
import { CaptionProps, useNavigation } from "react-day-picker";
import { Title } from "@umahealth/occipital";
import { es } from "date-fns/locale";

export function CustomCaptionComponent({
	title,
	className,
	...props
}: CaptionProps & { title?: string; className?: string }) {
	const { goToMonth, nextMonth, previousMonth, currentMonth } =
		useNavigation();

	const YearLessDate = setYear(
		startOfMonth(currentMonth),
		getYear(currentMonth) - 1
	);

	const YearAddDate = setYear(
		startOfMonth(currentMonth),
		getYear(currentMonth) + 1
	);
	return (
		<div
			className={cn(
				"border border-solid border-grey-5 border-b-0 rounded-tr-2xl rounded-tl-2xl",
				className
			)}
		>
			{title && (
				<Title
					className="flex items-center h-16 ml-3"
					hierarchy="h1"
					size="text-m"
					weight="font-semibold"
					color="text-grey-1"
				>
					{" "}
					Seleccione el mes y el año a visualizar{" "}
				</Title>
			)}
			<div className="flex w-full content-center items-center justify-between h-10">
				<div className="flex w-full content-center items-center justify-center">
					<button
						disabled={!previousMonth}
						onClick={() =>
							previousMonth && goToMonth(previousMonth)
						}
					>
						<Icon
							name="chevronLeft"
							className="stroke-1 fill-none"
						/>
					</button>
					<span className="w-full max-w-40 text-center font-semibold">
						{format(props.displayMonth, "MMMM", {
							locale: es,
						})}
					</span>
					<button
						disabled={!nextMonth}
						onClick={() => nextMonth && goToMonth(nextMonth)}
					>
						<Icon
							name="chevronLeft"
							className="stroke-1 fill-none rotate-180"
						/>
					</button>
				</div>
				<div className="flex w-full content-center items-center justify-center">
					<button
						disabled={!YearLessDate}
						onClick={() => YearLessDate && goToMonth(YearLessDate)}
					>
						<Icon
							className="stroke-1 fill-none"
							name="chevronLeft"
						/>
					</button>
					<span className="w-full max-w-40 text-center font-semibold">
						{format(props.displayMonth, "RRRR", {
							locale: es,
						})}
					</span>
					<button
						disabled={!YearAddDate}
						onClick={() => YearAddDate && goToMonth(YearAddDate)}
					>
						<Icon
							className="rotate-180 stroke-1 fill-none"
							name="chevronLeft"
						/>
					</button>
				</div>
			</div>
		</div>
	);
}


/**
 *
 * @returns Componente custom que renderiza el encabezado de días del calendario
 */
export function CustomHeadComponent({
	fullDay = true,
	elementClassname,
	rowClassName,
}: {
	fullDay?: boolean
	elementClassname?: string
	rowClassName?: string
}) {
	return (
		<thead className="block">
			<tr className={cn(rowClassName)}>
				<th
					scope="col"
					className={cn(
						'normal-case border-solid border border-grey-5 text-center h-11 w-[91px] text-base font-normal',
						elementClassname,
					)}
					aria-label="Lunes"
				>
					{fullDay ? 'Lunes' : 'L'}
				</th>
				<th
					scope="col"
					className={cn(
						'normal-case border-solid border border-grey-5 text-center h-11 w-[91px] text-base font-normal',
						elementClassname,
					)}
					aria-label="Martes"
				>
					{fullDay ? 'Martes' : 'M'}
				</th>
				<th
					scope="col"
					className={cn(
						'normal-case  border-solid border border-grey-5 text-center h-11 w-[91px] text-base font-normal',
						elementClassname,
					)}
					aria-label="Miércoles"
				>
					{fullDay ? 'Miércoles' : 'M'}
				</th>
				<th
					scope="col"
					className={cn(
						'normal-case  border-solid border border-grey-5 text-center h-11 w-[91px] text-base font-normal',
						elementClassname,
					)}
					aria-label="Jueves"
				>
					{fullDay ? 'Jueves' : 'J'}
				</th>
				<th
					scope="col"
					className={cn(
						'normal-case  border-solid border border-grey-5 text-center h-11 w-[91px] text-base font-normal',
						elementClassname,
					)}
					aria-label="Viernes"
				>
					{fullDay ? 'Viernes' : 'V'}
				</th>
				<th
					scope="col"
					className={cn(
						'normal-case  border-solid border border-grey-5 text-center h-11 w-[91px] text-base font-normal',
						elementClassname,
					)}
					aria-label="Sábado"
				>
					{fullDay ? 'Sábado' : 'S'}
				</th>
				<th
					scope="col"
					className={cn(
						'normal-case  border-solid border border-grey-5 text-center h-11 w-[91px] text-base font-normal',
						elementClassname,
					)}
					aria-label="Domingo"
				>
					{fullDay ? 'Domingo' : 'D'}
				</th>
			</tr>
		</thead>
	)
}


export function FilterRangeDates({
	selectedRange,
	setSelectedRange,
}: {
	selectedRange: DateRange | undefined;
	setSelectedRange: (dates: DateRange) => void;
}) {

	const date = new Date();

	return (
		<CalendarRange
			className="rounded-2xl bg-grey-50 w-[280px] p-2 py-4 "
			selected={selectedRange}
			onSelect={(props) => (props ? setSelectedRange(props) : undefined)}
			onDayClick={(day, modifiers) => {
				if (modifiers.range_start || modifiers.range_end) {
					setSelectedRange({
						from: day,
						to: undefined,
					});
				}
			}}
			classNames={{
				cell: "min-w-[39px] min-h-[30px] h-[30px] w-[39px] max-w-[39px] max-h-[30px] bg-grey-50 align-top text-grey-1 border-none p-0 last:w-[34.5px] last:max-w-[34.5px] last:min-w-[34.5px] relative -left-[4.5px] last:group ",
				button: "w-full h-full px-0 py-0 flex items-center justify-center",
				button_reset: "w-full h-full",
				row: " flex bg-grey-50 ",
				tbody: "space-y-2 bg-grey-50 py-2.5",
				day: "w-full h-full flex group  text-grey-700 disabled:text-grey-200",
				day_range_start:
					selectedRange?.to === undefined
						? "rounded-xl bg-secondary-500"
						: "rounded-l-xl bg-secondary-500",
				day_range_end: "rounded-r-xl bg-secondary-500",
				day_selected: "bg-secondary-300",
				day_range_middle: "bg-secondary-300",
				day_outside: "bg-grey-50 border-none text-grey-4",
				months: "bg-grey-50 rounded-2xl",
				head_row: "space-x-[9px] flex ",
				head_cell: "mr-2",
			}}
			showOutsideDays
			captionLayout="buttons"
			mode="range"
			toDate={date}
			components={{
				Head: () => (
					<CustomHeadComponent
						elementClassname="w-[30px] h-[30px] border-none text-grey-700"
						rowClassName=" space-x-[9px] flex bg-grey-50 border-none"
						fullDay={false}
					/>
				),
				Caption: (props) => (
					<CustomCaptionComponent
						className="bg-grey-50 border-none mb-2 text-grey-700"
						{...props}
					/>
				),
				DayContent: (props) => {
					if (props.activeModifiers.outside) {
						return (
							<span className="text-grey-200 disabled:text-grey-200">
								{getDate(props.date)}
							</span>
						);
					}

					if (
						props.activeModifiers.range_start ||
						props.activeModifiers.range_middle ||
						props.activeModifiers.range_end
					) {
						return (
							<span className="text-white relative">
								{getDate(props.date)}
							</span>
						);
					}

					if (props.activeModifiers.today) {
						return (
							<div className="flex flex-col items-center justify-end relative top-[2px] ">
								<span>{getDate(props.date)}</span>
								{props.activeModifiers.today && (
									<span className="bg-secondary-500 w-1 h-1 rounded-full" />
								)}
							</div>
						);
					}
					return (
						<span>
							{getDate(props.date)}
						</span>
					);
				},
			}}
		/>
	);
}