"use client";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { Icon } from "@umahealth/occipital/client";
import Link from "next/link";
import { StatusText } from "src/storybook/components/StatusText/StatusText";
import { IOperation } from "@umahealth/entities";
import { Paragraph } from "@umahealth/occipital";

export type AlertAIColumns = Pick<IOperation, "id" | "status" | "apto"> & {
	fullname: string;
	dt_start: Date;
	dt_finish: Date | null;
	hasAlarm: boolean;
	self_assestment: IOperation["self_assestment"];
};

export const alertAIColumns = [
	{
		id: "startDate",
		accessorKey: "startDate",
		header: ({ column }) => {
			return (
				<div className="flex items-center">
					<div className="text-left font-normal mr-2">Iniciado</div>
					<button
						className="flex items-center"
						onClick={() =>
							column.toggleSorting(column.getIsSorted() === "asc")
						}
					>
						<Icon aria-hidden name={"arrowUpDown"} />
					</button>
				</div>
			);
		},
		sortingFn: "datetime",
		cell: ({ row }) => {
			const date = new Date(row.original.dt_start);
			let formattedDate: string;
			let formattedTime: string;
			let accesibleDate: string;

			try {
				accesibleDate = date.toISOString();
			} catch {
				accesibleDate = "invalid";
			}

			try {
				formattedDate = format(date, "dd/MM/yyyy");
			} catch {
				formattedDate = "Fecha inválida";
			}
			try {
				formattedTime = format(date, "HH:mm");
			} catch {
				formattedTime = "hora inválida";
			}

			return (
				<div className="flex flex-col text-grey-400 ">
					<time
						dateTime={accesibleDate}
						aria-label={`Fecha ${formattedDate}`}
					>
						{formattedDate}
					</time>
					<time
						dateTime={accesibleDate}
						aria-label={`Hora ${formattedTime}`}
					>
						{formattedTime}
					</time>
				</div>
			);
		},
	},
	{
		id: "endDate",
		accessorKey: "endDate",
		header: ({ column }) => {
			return (
				<div className="flex items-center">
					<div className="text-left font-normal mr-2">Finalizado</div>
					<button
						className="flex items-center"
						onClick={() =>
							column.toggleSorting(column.getIsSorted() === "asc")
						}
					>
						<Icon aria-hidden name={"arrowUpDown"} />
					</button>
				</div>
			);
		},
		sortingFn: "datetime",
		cell: ({ row }) => {
			const date = row.original.dt_finish
				? new Date(row.original.dt_finish)
				: null;
			let formattedDate: string;
			let formattedTime: string;
			let accesibleDate: string;

			if (!date) {
				return (
					<div className="flex flex-col text-grey-400 ">
						<p>-</p>
					</div>
				);
			}

			try {
				accesibleDate = date.toISOString();
			} catch {
				accesibleDate = "invalid";
			}

			try {
				formattedDate = format(date, "dd/MM/yyyy");
			} catch {
				formattedDate = "Fecha inválida";
			}
			try {
				formattedTime = format(date, "HH:mm");
			} catch {
				formattedTime = "hora inválida";
			}

			return (
				<div className="flex flex-col text-grey-400 ">
					<time
						dateTime={accesibleDate}
						aria-label={`Fecha ${formattedDate}`}
					>
						{formattedDate}
					</time>
					<time
						dateTime={accesibleDate}
						aria-label={`Hora ${formattedTime}`}
					>
						{formattedTime}
					</time>
				</div>
			);
		},
	},
	{
		id: "id",
		accessorKey: "id",
		header: () => {
			return <div className="font-normal"># Operación</div>;
		},
		cell: ({ row }) => {
			return (
				<div className="flex text-grey-400 flex-wrap">
					<p>{row.original.id}</p>
				</div>
			);
		},
	},
	{
		id: "fullname",
		accessorKey: "fullname",
		sortingFn: "text",
		header: ({ column }) => {
			return (
				<div className="flex items-center content-center">
					<div className="text-left font-normal mr-2">Operador</div>
					<button
						className="flex items-center"
						onClick={() =>
							column.toggleSorting(column.getIsSorted() === "asc")
						}
					>
						<Icon name="sortByAlpha" />
					</button>
				</div>
			);
		},

		cell: ({ row }) => {
			return (
				<div className="flex text-grey-400 flex-wrap">
					<p>
						{row.original.fullname}
					</p>
				</div>
			);
		},
	},
	{
		id: "status",
		accessorKey: "status",
		header: () => (
			<div className="text-left font-normal">
				Resultado de la evaluación
			</div>
		),
		cell: ({ row }) => {
			const self_assestment = row.original.self_assestment;

			if (!self_assestment){
					return (
						<StatusText
							status={"in progress"
							}
						/>
					);
			}

			if (row.original.apto){
				return (
					<StatusText
						status={
							self_assestment
								? self_assestment
									? "apto"
									: "no apto"
								: "in progress"
						}
					/>
				);
			}

			return (
				<StatusText
					status={
						row.original.hasAlarm ? "revisión pendiente" : "apto"
					}
				/>
			);
		},
		filterFn: (row, id, filterValue: string[]) => {
			return filterValue.includes(row.getValue(id));
		},
	},
	{
		id: "actions",
		accessorKey: "actions",
		header: () => {
			return <div className="text-left font-normal">Acciones</div>;
		},
		cell: ({ row }) => {
			return (
				<div className="flex items-center">
					<Link
						className="mr-0 flex w-full"
						aria-label={`Ir a la consulta ${row.original.id}`}
						href={`/operaciones/${row.original.id}/`}
					>
						{row.original.hasAlarm && (
							<Paragraph
								className="italic w-[94px]"
								color="text-error-500"
								weight="font-semibold"
							>
								ver alarma
							</Paragraph>
						)}
						<Icon
							aria-hidden
							name="chevronRight"
							color={
								row.original.hasAlarm
									? "text-error-500"
									: "text-grey-600"
							}
							size="size-6"
						/>
					</Link>
				</div>
			);
		},
	},
] as const satisfies ColumnDef<AlertAIColumns>[];

export const columnsIds = alertAIColumns.map((column) => column.id);

export type DashboardColumnsIds = (typeof alertAIColumns)[number]["id"];
