"use client";
import { Button } from "@umahealth/occipital";
import {
	Icon,
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@umahealth/occipital/client";
import { Dispatch, SetStateAction, startTransition, useOptimistic, useState } from "react";
import * as Checkbox from "@radix-ui/react-checkbox";
import { addDashboardColumnToCookies } from "@/modules/operations/(trenes)/infraestructure/service/addDashboardColumnToCookies";
import { removeDashboardColumn } from "@/modules/operations/(trenes)/infraestructure/service/removeDashboardColumnFromCookies";
import { Column } from "@tanstack/react-table";
import { cn } from "@/utils/cn";
import { IOperationDashboard } from "src/storybook/pages/administracion/Operations/OperationsPage.stories";
import { IGetOperation } from "@/modules/operations/(trenes)/infraestructure/useGetOperations";

/** Interfaz para decidir que columnas mostrar */
export type IColumnsToShow = string[];

export function removeValueFromArray<T>(array: T[], value: T): T[] {
	const index = array.indexOf(value);

	if (index !== -1) {
		array.splice(index, 1);
	} else {
		console.log(`${value} no se encuentra en el array.`);
	}

	return array;
}

export function ColumnasDashboard({
	checkedColumns,
	allColumns,
}: {
	checkedColumns: string[];
	allColumns: Column<IOperationDashboard, unknown>[];
}) {
	const [columns, setColumns] = useOptimistic(checkedColumns);

	async function addColumnToShow(columnToShow: Column<IOperationDashboard, unknown>) {
		if (!columns.includes(columnToShow.id)) {
			await addDashboardColumnToCookies(columnToShow.id);
			columnToShow.toggleVisibility(true);
			startTransition(() => {
				setColumns([...columns, columnToShow.id]);
			});
		}
	}

	async function removeColumn(columnToHide: Column<IOperationDashboard, unknown>) {
		await removeDashboardColumn(columnToHide.id);
		columnToHide.toggleVisibility(false);
		startTransition(() => {
			setColumns([...removeValueFromArray(columns, columnToHide.id)]);
		});
	}

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					className="w-[106px] border-grey-500 text-grey-500 h-[50px] flex items-center justify-center mr-2 rounded-2xl"
					type="button"
					variant="outlined"
				>
					<span className="ml-2">Columnas</span>
				</Button>
			</PopoverTrigger>
			<PopoverContent className="flex flex-col">
				{allColumns.map((column) => {
					return (
						<div
							className="flex items-center justify-start p-2 group hover:bg-grey-100 rounded-md"
							key={column.id}
						>
							<Checkbox.Root
								checked={columns.includes(column.id)}
								id={column.id}
								className="w-5 h-5 mr-2 rounded-sm aria-checked:bg-primary-500 bg-none border-primary-500 border border-solid"
								onClick={() =>
									!columns.includes(column.id)
										? addColumnToShow(column)
										: removeColumn(column)
								}
							>
								<Checkbox.Indicator className="w-full h-full flex items-center justify-center">
									<Icon
										name="check"
										size="size-4"
										color="text-grey-6"
									/>
								</Checkbox.Indicator>
							</Checkbox.Root>
							<label
								className="cursor-pointer w-full"
								htmlFor={column.id}
							>
								{column.id}
							</label>
						</div>
					);
				})}
			</PopoverContent>
		</Popover>
	);
}

export interface IFilterObject {
	/** Texto de fantasía para mostarle al usuario */
	label: string,
	id: number|string
}

export function FilterByRange({
	elementsToSee,
	allElements,
	columnToFilter,
	title,
	setFilters,
	filters,
}: {
	elementsToSee: IFilterObject[];
	allElements: IFilterObject[];
	columnToFilter: Column<IOperationDashboard, unknown> | undefined;
	title: "Estado" | "Base";
	setFilters: Dispatch<SetStateAction<IGetOperation["filters"]>>;
	filters: IGetOperation["filters"];
}) {
	const [filteredElements, setFilteredElements] = useState(elementsToSee);
	const somethingIsFiltered = elementsToSee.length < allElements.length;

	async function addElementToShow(elementToAdd: IFilterObject) {
		if (!filteredElements.some((element) => element.id === elementToAdd.id)) {
			const newStatus = [...filteredElements, elementToAdd];
			setFilteredElements([...filteredElements, elementToAdd]);

			const selectedFilter = title === "Base" ? "base" : "type";
			let value = filters?.[selectedFilter] !== undefined ? String(filters?.[selectedFilter]) : ''

			const temp = value.split(',').filter(Boolean)

			if(!temp.includes(String(elementToAdd.id))) {
				temp.push(String(elementToAdd.id))
				value = temp.join(',')
			}

			if (filters) {
				setFilters({
					...filters,
					[selectedFilter]: value,
				});
			} else {
				setFilters({
					[selectedFilter]: value,
				});
			}
			startTransition(() => {
				columnToFilter?.setFilterValue(newStatus);
			});
		}
	}

	async function removeElementToShow(elementToRemove: IFilterObject) {
		const selectedFilter = title === "Base" ? "base" : "type";

		if (filteredElements.some((element) => element.id === elementToRemove.id)) {
			const newStatus = filteredElements.filter((element) => element.id !== elementToRemove.id);
				if (filters) {
					setFilters({
						...filters,
						[selectedFilter]: newStatus.map(({id}) => id).join(',') ?? undefined,
					});
				} else {
					setFilters({
						[selectedFilter]: newStatus.map(({id}) => id).join(',') ?? undefined,
					});
				}
			setFilteredElements(newStatus);
			startTransition(() => {
				columnToFilter?.setFilterValue(newStatus);
			});
		}
	}

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					className={cn(
						somethingIsFiltered
							? "border-primary-800 bg-primary-50"
							: "border-grey-500",
						"w-[106px] text-grey-500 h-[50px] flex items-center justify-center mr-2 rounded-full"
					)}
					type="button"
					variant="outlined"
				>
					<span
						className={cn(
							"ml-2",
							somethingIsFiltered
								? "text-primary-700"
								: "text-grey-500"
						)}
					>
						{title}
					</span>
				</Button>
			</PopoverTrigger>
			<PopoverContent className="flex flex-col">
				{allElements.map((currentStatus) => {
					return (
						<div
							className="flex items-center justify-start p-2 group hover:bg-grey-100 rounded-md"
							key={currentStatus.id}
						>
							<Checkbox.Root
								checked={filteredElements.some(
									(filteredElement) =>
										filteredElement.id === currentStatus.id
								)}
								id={currentStatus.id.toString()}
								className="w-5 h-5 mr-2 rounded-sm aria-checked:bg-primary-500 bg-none border-primary-500 border border-solid"
								onClick={() =>
									!filteredElements.some( element => element.id === currentStatus.id)
										? addElementToShow(currentStatus)
										: removeElementToShow(currentStatus)
								}
							>
								<Checkbox.Indicator className="w-full h-full flex items-center justify-center">
									<Icon
										name="check"
										size="size-4"
										color="text-grey-6"
									/>
								</Checkbox.Indicator>
							</Checkbox.Root>
							<label
								className="cursor-pointer w-full"
								htmlFor={currentStatus.id.toString()}
							>
								{currentStatus.label}
							</label>
						</div>
					);
				})}
			</PopoverContent>
		</Popover>
	);
}
