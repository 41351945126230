"use client"
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { objectsAreEquals } from '@/utils/functions';
import { IOperationFilters } from '../../domain/OperationsTable';
import { IOperation, IOperationResponse } from '@umahealth/entities';
import { fetchOperations } from '../../infraestructure/operationsRequets';
import { compareAlarms, generateRandomString } from './utils';

interface AlarmCheckerProps {
    refreshTable: (str: string) => void;
    setPage: Dispatch<SetStateAction<number>>
    filters: IOperationFilters,
    filtersInitialState: IOperationFilters,
    setFilters: Dispatch<SetStateAction<IOperationFilters>>;
    baseId : number
}

const AlarmChecker = ({ setPage, refreshTable, filters, filtersInitialState, setFilters, baseId }: AlarmCheckerProps) => {
    const [operationsChecker, setOperationsChecker] = useState<IOperation[]>([]);
    const base_id = baseId ?? 0;

    useEffect(() => {
        const getInProgressOperations = async () => {
            // Get in progress operations
            const operationsRequest: IOperationResponse = await fetchOperations({ 
                filters: { type: 'all', driver: null, from: null, to: null, base: base_id },
                page: null,
                exportCSV: true
            });
            
            // if current alarms are not equals to new alarms, refresh table to show new alarms
            if(operationsChecker.length > 0 && !compareAlarms(operationsChecker, operationsRequest.operations)){
                // check if there is some filter applied
                if(!objectsAreEquals(filters, filtersInitialState)){
                    // reset filters (this automatically updates the table)
                    setFilters(filtersInitialState);
                }else{
                    setPage(actualPage => {
                        if(actualPage !== 1){
                            // if current page is not equal to 1, set page to 1 and refresh
                            return 1;
                        } else {
                            // if current page is equal to 1, refresh table
                            refreshTable(generateRandomString());
                            return actualPage;
                        }
                    });
                }
                // set current operations state with the new operations
                setOperationsChecker(operationsRequest.operations);
            }

            return operationsRequest;
        }

        if(operationsChecker.length === 0) {
            // Get operations on first load
            getInProgressOperations().then(operations => setOperationsChecker(operations.operations))
            
        }

        const intervalMinutes = 5;
        const checkerInterval = setInterval(() => {
            getInProgressOperations();
        }, (1000 * 60) * intervalMinutes);

        // On component unmount clear interval
        return () => clearInterval(checkerInterval)
    }, [operationsChecker, refreshTable, setPage, base_id, filters, filtersInitialState, setFilters]);
    
    return <></>;

}

export default AlarmChecker