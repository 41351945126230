'use client'
import React, { useEffect, useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Loader, Pagination } from '@/shared/index' 
import OperationTableFilters from './OperationsTableFilters'
import ExportOperations from './ExportOperations'
import Link from 'next/link'
import { checkOperationsHasAlarm } from '@/modules/operations/(cemex)/app/components/utils'
import { IOperationTableProps } from '@/modules/operations/(cemex)/domain/OperationsTable'
import { AlertAIColumns, alertAIColumns } from 'src/storybook/components/Dashboard/Operations/AlertAI/AlertAIOperationsColumns'

export default function OperationsTable({operations, total, page, setPage, operationsLoading, filtersInitialState, filters, setFilters, refreshTable}: IOperationTableProps) {

  const [columns] = useState<typeof alertAIColumns>(() => [...alertAIColumns]);

  const table = useReactTable({
    data: operations.map(operation => {
      return {
			apto: operation.apto,
			dt_finish: operation.dt_finish
				? new Date(operation.dt_finish)
				: null,
			dt_start: new Date(operation.dt_start),
			fullname: `${operation.user.firstName} ${operation.user.lastName}`,
			id: operation.id,
			status: operation.status,
			hasAlarm: checkOperationsHasAlarm(operation.tests),
			self_assestment: operation.self_assestment,
		} satisfies AlertAIColumns;
    }),
    columns,
    manualPagination: true,
    pageCount: Math.ceil(total / 10),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  })

  const [state, setState] = useState(table.initialState)
  table.setOptions(prev => ({
    ...prev,
    state,
    onStateChange: setState,
    debugTable: state.pagination.pageIndex > 2,
  }))

  // When page number changes, update paginator counter
  useEffect(() => {
    table.setPageIndex(page-1)
  }, [table, page])

  useEffect(() => {
    setPage(state.pagination.pageIndex + 1)
  }, [setPage, state.pagination.pageIndex])

  // if any filter changes, reset pagination to page 1
  useEffect(() => {
    table.setPageIndex(0)
  }, [table, filters])

  return (
      <div className='h-full'>
          <div className="flex justify-between items-center py-12">
              <OperationTableFilters initialState={filtersInitialState} filters={filters} setFilters={setFilters}>
                  <ExportOperations operations={operations} filters={filters} />
              </OperationTableFilters>
              <Link href={'./operaciones/crear'} className="button w-60 max-w-60 flex justify-center items-center">
                  Agregar operación
              </Link>
          </div>

          <div className='relative mb-20'>
                  <>
                      <table>
                          <thead>
                              {table.getHeaderGroups().map(headerGroup => (
                                  <tr key={headerGroup.id}>
                                      {headerGroup.headers.map(header => (
                                          <th key={header.id} colSpan={header.colSpan}>
                                              {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                                          </th>
                                      ))}
                                  </tr>
                              ))}
                          </thead>
                          <tbody>
                              {table?.getRowModel()?.rows.map(row => {
                                  return (
										<tr
											key={row.id}
											className={row.original.hasAlarm ? "bg-red-200" : ""}
										>
											{row
												.getVisibleCells()
												.map((cell) => (
													<td key={cell.id}>
														{flexRender(
															cell.column
																.columnDef.cell,
															cell.getContext()
														)}
													</td>
												))}
										</tr>
									);
                              })}
                          </tbody>
                      </table>
                      <Pagination table={table} isLoading={operationsLoading} />
                  </>
              {operationsLoading && (
                  <div className="absolute h-full w-full z-10 top-0 bg-white/70 flex justify-center items-center">
                      <Loader />
                  </div>
              )}
          </div>
      </div>
  )
}