import { adminRolesArray } from "@/config/adminRoles"
import { IUserRole } from "@/modules/users/domain/IUser"; 

export function userIsAllowed(userRoles: IUserRole[]): boolean {
    return userRoles.some((role: IUserRole) => adminRolesArray.includes(role.id))
}

export function generateRandomPassword(): string {
    const allowedCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const specialCharacters = '!@#$%^&*_+-=<>?';
    const maxSpecialCharacters = 3;
    const length = 12;
  
    let password = '';
    let specialCount = 0;
  
    for (let i = 0; i < length; i++) {
      let character = '';
  
      if (i < length - maxSpecialCharacters && Math.random() < 0.25 && specialCount < maxSpecialCharacters) {
        character = specialCharacters.charAt(Math.floor(Math.random() * specialCharacters.length));
        specialCount++;
      } else {
        character = allowedCharacters.charAt(Math.floor(Math.random() * allowedCharacters.length));
      }
  
      password += character;
    }
  
    return password;
  }

export function objectToQueryParams(object: Record<string, string | number | null>) {
    const queryParams = Object.keys(object).map(key => {
        return `${key}=${encodeURIComponent(object[key] ?? '')}`;
      }).join('&');
    
    return queryParams;
}

export function removeEmptyValuesFromObject(object: any) {
  const objectToClean = {...object}
  Object.keys(objectToClean).forEach(key => {
    if (objectToClean[key] === null || objectToClean[key] === '') {
      delete objectToClean[key];
    }
  });

  return objectToClean
}

export function objectsAreEquals(object1: any, object2: any) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !objectsAreEquals(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }

  return true;
}

function isObject(object: any) {
  return object != null && typeof object === 'object';
}
