import React, { Dispatch, SetStateAction, useState } from 'react'
import Modal from './Modal'
import AlertOperationsTable from '@/modules/operations/app/components/table/AlertOperationsTable';
import swal from 'sweetalert';
import useLocalStorage from '@/hooks/useLocalStorage';
import { IconAlertTriangle } from '@tabler/icons-react';
import { IOperation, IOperationResponse } from '@umahealth/entities';

interface AlertProps {
    data: IOperation[];
    setOperations: Dispatch<SetStateAction<IOperationResponse>>
}

function getTotalWarningsText(totalWarnings: number) {
	if (totalWarnings === 1) {
	  return <p><b>1 operación</b> excedió el tiempo límite para realizar el test de fatiga</p>
	} else {
	  return <p><b>{totalWarnings} operaciones</b> excedieron el tiempo límite para realizar el test de fatiga</p>
	}
}

const Alert = ({ data, setOperations }: AlertProps) => {
    const [alertModal, setAlertModal] = useState<boolean>(false);
    const [alertsReaded, setAlertsReaded] = useLocalStorage('alertsReaded', []);
    const totalWarnings = data.length;

    const handleMarkAsRead = async () => {
        const markAsReadConfirmation = await swal({
            title: "¿Estás seguro?",
            text: "Estas alertas no se volverán a mostrar nuevamente",
            icon: "warning",
            buttons: ['Cancelar', 'Continuar'],
        });

        if(!markAsReadConfirmation) return;

        
        const testIdsList = data.map(operation => {
            if(!operation.tests) return 0;
            return operation.tests?.map(test => test.id)
        }).flat();

        // Remove duplicates test Ids
        const newAlertsReaded = [...alertsReaded, ...testIdsList].filter((value, index, array) => array.indexOf(value) === index);
        
        setAlertsReaded(newAlertsReaded);
        setAlertModal(false);
        setOperations({ total: 0, operations: []});
    }

    if(totalWarnings === 0) return <></>;

    return (
        <>
            <div className="w-fit mx-auto flex justify-center items-center text-center gap-12 px-8 py-6 rounded-lg bg-yellow-200">
                <IconAlertTriangle size={26} stroke={1} />
				{getTotalWarningsText(totalWarnings)}
                <button onClick={() => setAlertModal(true)} className='text-black/80 hover:text-black'>Ver detalles</button>
            </div>
            <Modal title="Operaciones con alertas" status={alertModal} setStatus={setAlertModal} className="w-[1200px]">
                <AlertOperationsTable operations={data} />
                <div className="flex justify-center pt-20">
                    <button onClick={handleMarkAsRead} className='button w-80'>Marcar como revisadas</button>
                </div>
            </Modal>
        </>
    )
}

export default Alert