"use client";

import {
	IGetOperation,
	useInfiniteQueryGetOperations,
} from "../infraestructure/useGetOperations";
import { IOperator } from "@/modules/driver/domain/IOperator";
import { ISearchDashboardData } from "src/storybook/components/Inputs/Combobox/SearchDashboard/SearchDashboard";
import { useCallback, useMemo, useRef, useState } from "react";
import OperationsPage from "src/storybook/pages/administracion/Operations/OperationsPage";
import { IOperationDashboard } from "src/storybook/pages/administracion/Operations/OperationsPage.stories";
import { IDashboardElement } from "src/storybook/components/Dashboard/Operations/Trenes/OperationsDashboard";
import { IOperation } from "@umahealth/entities";
import { operationColumns } from "src/storybook/components/Dashboard/Operations/Trenes/TrenesOperationsColumns";

export function renderStatus({
	status,
	apto,
}: {
	status: IOperation["status"];
	apto: IOperation["apto"];
}): IOperationDashboard["status"] {
	switch (status) {
		case "finished":
			if (apto) {
				return "apto";
			} else {
				return "no apto";
			}
		case "in-progress":
			return "in progress";
		case "to-apt-review":
			return "revisión pendiente";
		default:
			return "in progress";
	}
}

function parseDateFromDDMMYYYY(dateStr: string | null): Date | undefined {
	if (!dateStr) return undefined;
	const [day, month, year] = dateStr.split("-").map(Number);
	if (!day || !month || !year) return undefined;
	return new Date(year, month - 1, day);
}

function getInitialStateFiltersFromUrl() {
	const urlParams = new URLSearchParams(window.location.search);
	const urlType = urlParams.get("type");
	const typeSplited = urlType?.split(",") as IOperation["status"][] | undefined
	const type = urlType === "all" ? urlType : typeSplited;

	const initialFilters: IGetOperation["filters"] = {
		base: urlParams.get("base") ? Number(urlParams.get("base")) : undefined,
		type: type ?? "all",
		from: parseDateFromDDMMYYYY(urlParams.get("from")),
		to: parseDateFromDDMMYYYY(urlParams.get("to")),
	};

	return initialFilters;
}

export function TrenesOperationView({
	bases,
	operators,
}: {
	bases: IDashboardElement["bases"];
	operators: IOperator[];
}) {
	const [filters, setFilters] = useState<IGetOperation["filters"]>(
		getInitialStateFiltersFromUrl()
	);

	const operations = useInfiniteQueryGetOperations({
		exportCSV: false,
		filters: {
			...filters,
			type: filters?.type ?? 'all'
		},
	});

	const tableContainerRef = useRef<HTMLDivElement>(null);

	const flatOperations = useMemo(
		() => operations.data?.pages?.flatMap((page) => page.operations) ?? [],
		[operations.data]
	);

	/** Las operaciones en el formato que deseo */
	const operationsFormatted: IOperationDashboard[] | undefined =
		flatOperations.map((operation) => {
			return {
				base: operation.user?.base?.name ?? "No definido",
				date: new Date(operation.dt_finish ?? operation.dt_start),
				fullname: `${operation.user?.lastName} ${operation.user?.firstName}`,
				legajo: operation.user?.nomina ?? "No definido",
				status: renderStatus({
					apto: operation.apto,
					status: operation.status ?? "in-progress",
				}),
				id: operation.id,
				enfermero: operation.created_by
					? `${operation.created_by?.lastName} ${operation.created_by?.firstName}`
					: "No definido",
				doctor: operation.doctor_name ?? "",
			} satisfies IOperationDashboard;
		});

	const totalFetched = flatOperations.length;
	const totalDBRowCount = operations.data?.pages?.[0]?.total ?? 0;

	//called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
	const fetchMoreOnBottomReached = useCallback(
		(containerRefElement?: HTMLDivElement | null) => {
			if (containerRefElement) {
				const { scrollHeight, scrollTop, clientHeight } =
					containerRefElement;
				//once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
				if (
					scrollHeight - scrollTop - clientHeight < 500 &&
					!operations.isFetching &&
					totalFetched < totalDBRowCount
				) {
					operations.fetchNextPage();
				}
			}
		},
		[totalFetched, totalDBRowCount, operations]
	);


	const operatorsFitlered: ISearchDashboardData[] = operators.map(
		(operator) => {
			return {
				fullname: `${operator.lastName} ${operator.firstName}`,
				legajo: operator.nomina,
			};
		}
	);

	return (
		<OperationsPage
			columns={operationColumns}
			data={operationsFormatted}
			bases={bases}
			searchData={operatorsFitlered}
			fetchMoreOnBottomReached={fetchMoreOnBottomReached}
			filters={filters}
			setFilters={setFilters}
		/>
	);
}
