"use client";
import {
	objectToQueryParams,
	removeEmptyValuesFromObject,
} from "@/utils/functions";
import { IOperationResponse } from "../domain/IOperation";
import { IOperationFilters } from "../domain/OperationsTable";
import { getFirebaseTokenFromCookies } from "@/modules/users/service/cookies";
import { operation_url } from "@/config/endpoints";
import { handleApiResponse } from "./utils/handleApiResponse";
import { errorHandler } from "@/config/errorReporting";
import {
	keepPreviousData,
	useInfiniteQuery,
	useQuery,
} from "@tanstack/react-query";
import { useAuthIdToken } from "src/services/react-query/useAuthIdToken";

export interface IGetOperation {
	filters?: IOperationFilters | null;
	page?: number | null;
	exportCSV?: boolean;
}

function dateToDDMMYYYY(date: Date): string {
	const day = String(date.getDate()).padStart(2, "0");
	const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed in JS
	const year = date.getFullYear();

	return `${day}-${month}-${year}`;
}

/**
 * Fetches a list of operations based on filters and pagination.
 * @param {Object} options - The options for fetching operations.
 * @param {IOperationFilters | null} [options.filters] - The filters to apply.
 * @param {number | null} [options.page] - The page number for pagination.
 * @param {boolean} [options.exportCSV=false] - Whether to export as CSV.
 * @returns {Promise<IOperationResponse>} - The list of operations.
 * @throws {Error} - If there's an error fetching the operations.
 */
async function getOperations(
	authIdToken: string,
	{ filters, page, exportCSV = false }: IGetOperation
): Promise<IOperationResponse> {
	try {
		if (!filters) {
			const headers = {
				Authorization: `Bearer ${authIdToken}`,
			};
			const response = await fetch(`${operation_url}`, {
				cache: "no-store",
				headers,
			});

			return await handleApiResponse(response);
		}

		const filtersToQuery = filters;
		page = page || null;

		const queryToUrl = objectToQueryParams({
			...removeEmptyValuesFromObject({
				...filtersToQuery,
				from: filtersToQuery.from
					? dateToDDMMYYYY(filtersToQuery.from)
					: undefined,
				to: filtersToQuery.to
					? dateToDDMMYYYY(filtersToQuery.to)
					: undefined,
				page,
				export: exportCSV,
			}),
		});

		const queryParams = objectToQueryParams({
			...removeEmptyValuesFromObject({
				...filtersToQuery,
				page,
				export: exportCSV,
			}),
		});
		window.history.pushState(null, "", `?${queryToUrl}`);

		const firebaseToken = await getFirebaseTokenFromCookies();
		const headers = { Authorization: `Bearer ${firebaseToken}` };

		const response = await fetch(`${operation_url}?${queryParams}`, {
			cache: "no-store",
			headers,
		});

		return await handleApiResponse(response);
	} catch (err) {
		errorHandler?.report(
			`Error en request de obtener lista de operaciones: ${err}`
		);
		throw new Error(JSON.stringify(err));
	}
}

export function useGetOperations(props: IGetOperation) {
	const authIdToken = useAuthIdToken();
	return useQuery({
		queryKey: ["getOperations", props.filters, props.page, props.exportCSV],
		enabled: !!authIdToken.data?.token.token,
		queryFn: () =>
			getOperations(authIdToken.data?.token.token!, {
				...props,
			}),
	});
}

export function useInfiniteQueryGetOperations({
	exportCSV,
	filters,
}: IGetOperation) {
	const authIdToken = useAuthIdToken();
	//react-query has a useInfiniteQuery hook that is perfect for this use case
	return useInfiniteQuery<IOperationResponse>({
		queryKey: [
			"operations",
			exportCSV, //refetch when sorting changes
			filters?.base ?? "no base filtered",
			filters?.driver ?? "no driver filtered",
			filters?.from?.toString() ?? "no from filtered",
			filters?.to?.toString() ?? "no to filtered",
			filters?.type ?? "no type filtered",
		],
		queryFn: async ({ pageParam = 1 }) => {
			const fetchedData = await getOperations(
				authIdToken.data?.token.token!,
				{
					exportCSV: exportCSV,
					filters: {
						...filters,
					},
					page: pageParam as number,
				}
			); //pretend api call
			return fetchedData;
		},
		enabled: !!authIdToken.data?.token.token,
		initialPageParam: 1,
		getNextPageParam: (_lastGroup, groups) => {
			return groups.length + 1;
		},
		refetchOnWindowFocus: true,
		placeholderData: keepPreviousData,
	});
}
