import { Title } from "@umahealth/occipital";
import { OperationCard } from "src/storybook/components/cards/OperationCard/OperationCard";
import { Dispatch, SetStateAction, forwardRef } from "react";
import OperationsDashboard, {
	IDashboardElement,
} from "src/storybook/components/Dashboard/Operations/Trenes/OperationsDashboard";
import { IGetOperation } from "@/modules/operations/(trenes)/infraestructure/useGetOperations";

/**
 * OperationsPage component renders the operations dashboard along with a greeting and a link to create new evaluations.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.columns - The columns to be shown in the dashboard table.
 * @param {Array<string>} props.columnsToShow - Array of column IDs that should be visible in the table.
 * @param {Array<Object>} props.operationsFormatted - The formatted data for the operations to be displayed in the table.
 * @param {Array<Object>} props.bases - The list of bases to be filtered in the dashboard.
 * @param {Array<Object>} props.operatorsFiltered - The filtered operators to search within the dashboard.
 * @param {Function} props.fetchMoreOnBottomReached - Callback function to fetch more data when the bottom of the table is reached.
 * @param {Object} props.filters - The filters applied to the operations.
 * @param {Function} props.setFilters - Function to update the filters state.
 * @param {React.Ref<HTMLDivElement>} ref - Forwarded ref to the dashboard component.
 * @returns {JSX.Element} The rendered OperationsPage component.
 */

export type OperationsPageProps = {
	columns: IDashboardElement["columns"];
	data: IDashboardElement["data"];
	bases: IDashboardElement["bases"];
	searchData: IDashboardElement["searchData"];
	fetchMoreOnBottomReached: IDashboardElement["fetchMoreOnBottomReached"];
	filters: IGetOperation["filters"];
	setFilters: Dispatch<SetStateAction<IGetOperation["filters"]>>;
};

export const OperationsPage = ({
	columns,
	data,
	bases,
	searchData,
	fetchMoreOnBottomReached,
	filters,
	setFilters,
}: OperationsPageProps) => {
	return (
		<div className="flex-col h-full w-full">
			<OperationCard className="max-w-full  90dvh my-0 ">
				<Title
					className="pl-8 pt-8"
					size="text-m"
					color="text-grey-700"
					hierarchy="h1"
				>
					Evaluaciones
				</Title>
				<OperationsDashboard
					columns={columns}
					data={data}
					bases={bases}
					searchData={searchData}
					fetchMoreOnBottomReached={fetchMoreOnBottomReached}
					filters={filters}
					setFilters={setFilters}
				/>
			</OperationCard>
		</div>
	);
};

OperationsPage.displayName = "OperationsPage";

export default OperationsPage;
