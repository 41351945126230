import React from 'react'
import Image from 'next/image'
import mapIllustration from '@/public/illustrations/map.png'

export default function EmptyTable():JSX.Element {
  return (
    <div className="flex flex-col justify-center items-center mt-20">
        <p className='italic font-light opacity-70'>No se han encontrado operaciones que cumplan con el criterio de filtros aplicados.</p>
        <Image src={mapIllustration} alt='map' />
    </div>
  )
}
