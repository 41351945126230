"use client"
import React, { useState } from 'react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {format} from 'date-fns'
import { IOperation } from '@umahealth/entities'
import { ViewOperationDetails } from '@/modules/operations/(cemex)/app/components/OperationActions'
import TrafficLights from '@/modules/operations/(cemex)/app/components/TrafficLights'
import EmptyTable from '@/modules/operations/(cemex)/app/components/EmptyTable'

const defaultColumns: ColumnDef<IOperation>[] = [
   {
    id: 'actions-column',
    accessorKey: 'accessor',
    header: 'Acciones',
    cell: ({ row: { original } }) => {
        return (
            <div className="flex justify-center gap-4">
                <ViewOperationDetails operationId={original.id} />
            </div> 
        )
    }
  },
  {
    header: 'Operador',
    cell: ({row: { original } }) => {
      return <span>{`${original?.user?.firstName} ${original?.user?.lastName}`}</span>
    }
  },
  {
    accessorKey: 'dt_start',
    header: 'Iniciado',
    cell: ({ row: { original } }) => {
      return <>{format(new Date(original.dt_start), 'HH:mm:ss dd/MM/yyyy')}</>
    } 
  },
  {
    accessorKey: 'number',
    header: '# Operación',
  },
  {
    id: 'reaction-column',
    header: 'Tiempos de reacción',
    accessorKey: 'reaction',
    cell: ({ row: { original } }) => {
      return <div className="flex justify-start flex-wrap gap-2">
      {
        !original?.tests || original?.tests?.length === 0
        ? <TrafficLights testList={original?.tests} />
        : original?.tests?.map(test => {
          return <TrafficLights key={test.id} testId={test.id} testList={original?.tests} />
        })
      }
      </div>
    } 
  }
]

export default function AlertOperationsTable({operations} : {operations : IOperation[]}) {
  const [columns] = useState<typeof defaultColumns>(() => [
    ...defaultColumns,
  ])

  const table = useReactTable({
    data: operations,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  const [state, setState] =useState(table.initialState)
  table.setOptions(prev => ({
    ...prev,
    state,
    onStateChange: setState,
    debugTable: state.pagination.pageIndex > 2,
  }))

  return (
    <div>
      <table>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table ? table?.getRowModel()?.rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          )) :
          <EmptyTable />}
        </tbody>
      </table>
    </div>
  )
}