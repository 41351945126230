import { ApiError, ApiResponse } from "@/types/apiResponses";

/**
 * Handles the API response and throws an error if the response is not ok.
 * @template T - The expected type of the response data.
 * @param {Response} response - The fetch response object.
 * @returns {Promise<T>} - The parsed response data.
 * @throws {Error} - If the response is not ok.
 */
export async function handleApiResponse<T>(response: Response): Promise<T> {
	let jsonRes: ApiResponse<T> 
	try {
	jsonRes = await response.json();

	if (!response.ok) {
		console.error(jsonRes);
		throw new Error(
			`${(jsonRes as ApiError).message}  ${(jsonRes as ApiError).statusCode}`
		);
	}

	} catch(err){
		throw new Error(`error en handleApiResponse: ${err}`)
	}
	
	return jsonRes as T;
}
