"use client";
import ReactDOMServer from "react-dom/server";
//@ts-ignore
import html2pdf from "html2pdf.js";
import { OperationReportPdf } from "./OperationReport";
import { IOperation } from "@umahealth/entities";

export const downloadOperationReport = (operation?: IOperation) => {
  const contentHtml = ReactDOMServer.renderToStaticMarkup(
    <OperationReportPdf operation={operation} />
  );

  var opt = {
      filename: operation ? `Evaluación ${operation.id} - ${operation.user?.firstName + " " + operation.user?.lastName}.pdf` : 'Formulario evaluación.pdf',
    image: { type: 'jpeg', quality: 1 },
    html2canvas: {
      dpi: 192,
      scale:4,
    },
  };

  html2pdf()
    .set(opt)
    .from(contentHtml)
    .save();
};