import { IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react'
import swal from 'sweetalert';
import { useDebounce } from 'use-debounce';
import { IUserBase } from '@/modules/users/domain/IUser'; 
import { getBaseList } from '@/modules/users/infraestructure/usersRequests'; 
import { objectsAreEquals } from '@/utils/functions'; 
import { auth } from '@/config/firebaseApp';
import { User } from 'firebase/auth';
import { IOperationFilters, IOperationTableFiltersProps } from '@/modules/operations/(cemex)/domain/OperationsTable';

const OperationTableFilters = ({ initialState, filters, setFilters, children }: IOperationTableFiltersProps) => {
  const [internalState, setInternalState] = useState<IOperationFilters>(initialState);
  const [driverDebouncedValue] = useDebounce(internalState.driver, 1000);
  const [fromDebouncedValue] = useDebounce(internalState.from, 1500);
  const [toDebouncedValue] = useDebounce(internalState.to, 1500);
  const [baseList, setBaseList] = useState<IUserBase[]>([]);

  const resetFilters = () => {
    setFilters(initialState);
    setInternalState(initialState);
  }
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    let targetValue: string | number = e.target.value;
    if(e.target.name === 'base') targetValue = parseInt(targetValue);
    const newFilter = { ...filters, [e.target.name]: targetValue }

    // By default save input on internal component state
    setInternalState(newFilter)

    // if you need refresh on input change add input name property to array
    if(['type', 'base'].includes(e.target.name)){
      setFilters(newFilter); // Apply filter and fetch filtered data
    }
  }
  
  useEffect(() => {
    setFilters((prevState: IOperationFilters) => {
      return { 
              ...prevState,
              driver: driverDebouncedValue,
              from: fromDebouncedValue,
              to: toDebouncedValue
            }
    })
  }, [driverDebouncedValue, fromDebouncedValue, toDebouncedValue, setFilters])

  useEffect(() => {
		if (auth?.currentUser) {
			const fetchBases = async ({
				currentUser,
			}: {
				currentUser: User;
			}) => {
				try {
					const baseListData = await getBaseList({
						currentUser: currentUser,
					});
					setBaseList(baseListData);
				} catch (error) {
					swal(
						"Ocurrió un problema",
						"No pudimos cargar la lista de bases, recarga la página o intenta más tarde",
						"warning"
					);
				}
			};

			fetchBases({
				currentUser: auth?.currentUser,
			});
		}
  }, []);


  useEffect(() => {
		setInternalState(filters);
  }, [filters]);

  return (
      <div className="flex items-center gap-4">
          <div className="flex flex-col min-w-80 w-full">
              <input
                  type="text"
                  name="driver"
                  placeholder="Operador"
                  autoComplete="off"
                  id="search-driver"
                  value={internalState.driver ?? ''}
                  onChange={handleChange}
              />
          </div>

          <div className="flex items-center gap-2">
              <input
                  type="date"
                  id="start-picker"
                  name="from"
                  value={internalState.from ? internalState.from : ''}
                  onChange={handleChange}
                  max="2030-12-12"
              />
              {' - '}
              <input
                  type="date"
                  id="finish-picker"
                  name="to"
                  value={internalState.to ? internalState.to : ''}
                  onChange={handleChange}
                  max="2030-12-12"
              />
          </div>

          <select id="base-dropdown" className="w-fit" name="base" value={internalState.base} onChange={handleChange}>
              <option value="0">Todas</option>
              {baseList.map(base => {
                  return (
                      <option key={base.id} value={base.id}>
                          {base.name}
                      </option>
                  )
              })}
          </select>

          <select id="type-dropdown" className="w-fit" name="type" value={internalState.type} onChange={handleChange}>
              <option value="in-progress">En curso</option>
              <option value="finished">Finalizado</option>
              <option value="all">Todos</option>
          </select>

          {!objectsAreEquals(filters, initialState) && (
              <button onClick={resetFilters} className='flex gap-2 items-center'>
				<IconX size={28} color='red' stroke={1} />
              </button>
          )}

          {children}
      </div>
  )
}

export default OperationTableFilters