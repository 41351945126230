import { Paragraph } from "@umahealth/occipital";
import { CardIcon } from "../cards/Utils/Card";

export function StatusText({
	status,
}: {
	status:
		| "apto"
		| "no apto"
		| "in progress"
		| "revisión pendiente"
		| undefined
		| null
}) {

	if (status === "apto") {
		return (
			<>
				<CardIcon name={"checkFilled"} color={"text-success-600"}  text='Apto'/>
			</>
		);
	}

	if (status === "no apto") {
        console
		return (
			<>
				<CardIcon name={"cancelFilled"} color={"text-error-600"} text='No apto' />
			</>
		);
	}

	if ((status === "revisión pendiente")) {
		return (
			<Paragraph
				className="italic"
				color="text-grey-500"
				weight="font-semibold"
			>
				revisión pendiente
			</Paragraph>
		);
	}

	if (status === "in progress") {
		return (
			<Paragraph
				className="italic"
				color="text-grey-500"
				weight="font-semibold"
			>
				En proceso
			</Paragraph>
		);
	}

	return (
		<Paragraph
			className="italic"
			color="text-grey-500"
			weight="font-semibold"
		>
			{" "}
			{status ?? "Sin definir"}{" "}
		</Paragraph>
	);
}
