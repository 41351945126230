import pino from 'pino'

const levels = {
  default: 0,
  debug: 100,
  info: 200,
  notice: 300,
  warning: 400,
  error: 500,
  critical: 600,
  alert: 700,
  emergency: 800
};

const baseLogger = pino({
  customLevels: levels,
  useOnlyCustomLevels: true,
  messageKey: 'message',
  formatters: {
    level: (label) => {
      return { severity: label.toUpperCase() };
    },
    bindings: (bindings) => {
      return { };
    },
  },
  timestamp: false,
});

export default baseLogger