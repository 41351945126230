import { flexRender, Table } from "@tanstack/react-table";
import {
	Table as TableComponent,
	TableBody,
	TableCell,
	TableRow,
} from "src/shadcn/components/ui/table";
import { operationColumns, DashboardColumnsIds } from "./TrenesOperationsColumns";
import { forwardRef } from "react";
import { cn } from "@/utils/cn";
import { IOperationDashboard } from "src/storybook/pages/administracion/Operations/OperationsPage.stories";
import { Image, Paragraph } from "@umahealth/occipital";
import EmptyTable from 'public/assets/EmptyTable.svg'
export interface DataTableProps {
	columns: typeof operationColumns;
	data: IOperationDashboard[];
	fetchMoreOnBottomReached: (event: HTMLDivElement) => void;
	table: Table<IOperationDashboard>;
}

export const TrenesDashboardTable = forwardRef<HTMLDivElement, DataTableProps>(
	({ columns, table, fetchMoreOnBottomReached }, ref) => {
		const columnSizes: Record<DashboardColumnsIds, string> = {
			actions: "w-20 min-w-20",
			date: "w-32 min-w-32",
			status: "w-40 min-w-40",
			fullname: "w-40",
			enfermero: "w-40 ",
			doctor: "w-40 ",
			id: "w-10 min-w-10",
			base: "w-40 min-w-40",
		};

		const dataLength = table.getRowModel().rows.length;
		const calculedHeight =
			dataLength * 50 > 600 ? 600 : dataLength * 50 + 60;
		const tableIsEmpty = table.getRowModel().rows.length === 0;

		const tableHeight = tableIsEmpty
			? "600px"
			: (`${calculedHeight}px` as const);

		return (
			<div className="h-3/4">
				<div
					className="rounded-xl overflow-scroll overflow-x-scroll relative max-h-full"
					ref={ref}
					onScroll={(e) =>
						fetchMoreOnBottomReached(e.target as HTMLDivElement)
					}
					style={{
						//should be a fixed height
						height: tableHeight,
						width: `100%`,
					}}
				>
					<TableComponent
						className={cn(
							"w-full",
							tableIsEmpty
								? "h-min table-fixed"
								: "h-min table-fixed"
						)}
					>
						<thead className="bg-[#F5F7F9] text-grey-900 font-semibold sticky top-0">
							{table.getHeaderGroups().map((headerGroup) => (
								<TableRow key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										return (
											<th
												className={cn(
													tableIsEmpty
														? "w-full"
														: columnSizes[
																header.column
																	.columnDef
																	.id as DashboardColumnsIds
															]
												)}
												key={header.id}
											>
												{header.isPlaceholder
													? null
													: flexRender(
															header.column
																.columnDef
																.header,
															header.getContext()
														)}
											</th>
										);
									})}
								</TableRow>
							))}
						</thead>
						<TableBody>
							{tableIsEmpty ? (
								<TableRow>
									<TableCell
										colSpan={columns.length}
										className="w-full text-center"
									>
										<div className="flex flex-col h-full w-full justify-center items-center py-20">
											<Image src={EmptyTable.src} width={160} height={160} alt=''/>
											<Paragraph color="text-grey-500">
												No encontramos evaluaciones que
												coincidan con tu búsqueda.
												Intentalo nuevamente.
											</Paragraph>
										</div>
									</TableCell>
								</TableRow>
							) : (
								table.getRowModel().rows.map((row) => {
									return (
										<TableRow
											key={row.id}
											data-state={
												row.getIsSelected() &&
												"selected"
											}
										>
											{row
												.getVisibleCells()
												.map((cell) => (
													<td
														className="p-4 w-auto max-w-xs h-10 min-h-[54px] max-h-[54px]"
														key={cell.id}
													>
														{flexRender(
															cell.column
																.columnDef.cell,
															cell.getContext()
														)}
													</td>
												))}
										</TableRow>
									);
								})
							)}
						</TableBody>
					</TableComponent>
				</div>
			</div>
		);
	}
);

TrenesDashboardTable.displayName = "TrenesDashboardTable";
